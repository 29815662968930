import { template as template_8f090a934cc143f9913c9690d6b8f907 } from "@ember/template-compiler";
const FKText = template_8f090a934cc143f9913c9690d6b8f907(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
