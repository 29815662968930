import { template as template_df81c89e6a0d44a2bf7652c79cd35d8d } from "@ember/template-compiler";
const EmptyState = template_df81c89e6a0d44a2bf7652c79cd35d8d(`
  <div class="empty-state-container">
    <div class="empty-state">
      <span data-test-title class="empty-state-title">{{@title}}</span>
      <div class="empty-state-body">
        <p data-test-body>{{@body}}</p>
      </div>
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default EmptyState;
