import { template as template_f27491c8c95f4e67a74ee17961b5b9eb } from "@ember/template-compiler";
import { concat, hash } from "@ember/helper";
import { LinkTo } from "@ember/routing";
import { i18n } from "discourse-i18n";
const GroupActivityFilter = template_f27491c8c95f4e67a74ee17961b5b9eb(`
  <li>
    <LinkTo
      @route={{concat "group.activity." @filter}}
      @query={{hash category_id=@categoryId}}
    >
      {{i18n (concat "groups." @filter)}}
    </LinkTo>
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default GroupActivityFilter;
