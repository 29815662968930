import { template as template_39e1d8b9b11f463f95021a4a7d225680 } from "@ember/template-compiler";
const WelcomeHeader = template_39e1d8b9b11f463f95021a4a7d225680(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
