import { template as template_23c4051cfe504bbd85055ff13b67899b } from "@ember/template-compiler";
const FKLabel = template_23c4051cfe504bbd85055ff13b67899b(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
