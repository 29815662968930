import { template as template_67f5cbe066b84d9b96ce010f8bcc7866 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_67f5cbe066b84d9b96ce010f8bcc7866(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
