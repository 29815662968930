import { template as template_4aa784fb91654cd19da4edd58b3552a5 } from "@ember/template-compiler";
const FKControlMenuContainer = template_4aa784fb91654cd19da4edd58b3552a5(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
